import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import companyLogo from "../../Assets/khrouch.png";
import { Button, Divider, Form, Input, Popconfirm, Table, message } from "antd";
import moment from "moment";

export default function QuotationCard({ record, fetchQuotationById }) {
  console.log("record record", record?.quoteStatus);
  const dispatch = useDispatch();
  const [pdfFile, setPdfFile] = useState("");
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      profileState: state.ProfileReducer,
      clientState: state.ClientsReducer,
    };
  });

  useEffect(() => {
    dispatch({
      type: "FETCH_QOUTEPDFFILE_REQUEST",
      payload: { quotationId: record?.id },
      isSuccessFn: true,
      successFunction: (res) => {
        setPdfFile(res?.data);
      },
    });
  }, []);

  const printPdf = () => {
    const blob = base64ToBlob(pdfFile);
    const url = window.URL.createObjectURL(blob);
    // Open a new window with the PDF content
    const printWindow = window.open(url, "_blank");

    // Wait for the window to load before triggering the print dialog
    printWindow.onload = () => {
      printWindow.print();
      printWindow.onafterprint = () => {
        // Close the window after printing
        printWindow.close();
        window.URL.revokeObjectURL(url);
      };
    };
  };

  const downloadPdfFile = () => {
    const blob = base64ToBlob(pdfFile);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Quotation_Package.pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const base64ToBlob = (base64Data) => {
    const binaryData = atob(base64Data);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    return new Blob([uint8Array], { type: "application/pdf" });
  };

  const handleConfirmApproveQuote = () => {
    dispatch({
      type: "REQUEST_APPROVE_QUOTE_REQUEST",
      payload: { quotationId: record?.id },
      isSuccessFn: true,
      successFunction: (res) => {
        message.success(res?.data?.message);
        fetchQuotationById();
      },
    });
  };

  return (
    <div
      style={{ display: "grid", gridTemplateColumns: "4fr 1fr", gap: "1rem" }}
    >
      <div>
        <div
          style={{
            padding: "0.5rem",
            background: "#ecedf060",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "1.5rem 1rem",
              border: "1px solid #00000010",
            }}
          >
            <QuotationCardHeader
              record={record}
              companyData={store?.profileState?.profile}
            />
            <Divider />
            <QuotationCardBody
              record={record}
              companyData={store?.profileState?.profile}
            />
            <h4 style={{ margin: "1rem 0" }}>
              <strong>Nature of Project: </strong>
              {record?.natureOfProject}
            </h4>
            <QuotationCardProjectFeature
              record={record}
              companyData={store?.profileState?.profile}
            />

            <QuotationPackageDescription
              record={record}
              companyData={store?.profileState?.profile}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "1rem 0",
          }}
        >
          <Popconfirm
            placement="topLeft"
            title="Do you want to approve the quote?"
            onConfirm={handleConfirmApproveQuote}
          >
            {(record?.quoteStatus === "WIN" ||
              record?.quoteStatus == "QUOTE_SENT") && (
              <Button type="primary">Approve</Button>
            )}
          </Popconfirm>

          <div>
            <Button onClick={downloadPdfFile} type="primary">
              Download
            </Button>{" "}
            <Button onClick={printPdf} type="outline">
              Print
            </Button>
          </div>
        </div>
      </div>
      {/* <div>
        <Form layout="vertical">
          <h4>Add Comment:</h4>
          <Form.Item
            name="comment"
            rules={[{ required: true, message: "Comment is required" }]}
          >
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
        <Divider />
      </div> */}
    </div>
  );
}

export const QuotationCardHeader = ({ record, companyData }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div>
        <h2 style={{ margin: 0 }}>QUOTE</h2>
        <h3 style={{ margin: 0 }}>#{record?.id}</h3>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          gap: "0",
        }}
      >
        <p style={{ margin: 0 }}>{companyData?.companyName}</p>
        <p style={{ margin: 0 }}>
          {companyData?.companyAddress} ,{companyData?.country}
        </p>
        <p style={{ margin: 0 }}>
          Phone: ({companyData?.callingCode}) {companyData?.phoneNumber}
        </p>
        <p style={{ margin: 0 }}>{companyData?.emailId}</p>
        {companyData?.companyLogo && (
          <img
            style={{ width: "6rem", height: "auto" }}
            // src={companyLogo}
            src={companyData?.companyLogo}
            alt="companyLogo"
          />
        )}
      </div>
    </div>
  );
};

export const QuotationCardBody = ({ record, companyData }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div>
        <h4>
          <strong>Attn:</strong>
        </h4>
        <p style={{ margin: 0 }}>
          <strong>Name:</strong> {record?.customerId?.fullName}
        </p>
        <p style={{ margin: 0 }}>
          <strong>Phone:</strong> {record?.customerId?.mobile}
        </p>
        <p style={{ margin: 0 }}>
          <strong>Email:</strong> {record?.customerId?.email}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <h4>
          <strong>{record?.quoteStatus}</strong>
        </h4>
        <p style={{ margin: 0 }}>
          Quote date: {moment.utc(record?.addedDateTime).format("DD-MM-YYYY")}
        </p>
        <p style={{ margin: 0 }}>
          Quote expiry date:{" "}
          {moment.utc(record?.quoteExpiryDate).format("DD-MM-YYYY")}
        </p>
      </div>
    </div>
  );
};

export const QuotationCardProjectFeature = ({ record, companyData }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // justifyContent: "",
      }}
    >
      <h4>
        <strong>Project Features:</strong>
      </h4>
      <Divider style={{ margin: "0" }} />
    </div>
  );
};

export const QuotationPackageDescription = ({ record, companyData }) => {
  return (
    <div>
      <Divider style={{ margin: "1.5rem 0 0.2rem" }} />
      <div style={{ display: "grid", gridTemplateColumns: "2fr 1fr 1fr 1fr" }}>
        <h4 style={{ margin: "0", fontWeight: "bolder", fontWeight: "bolder" }}>
          Descriptions:
        </h4>
        <h4 style={{ margin: "0", textAlign: "end", fontWeight: "bolder" }}>
          Quantity:
        </h4>
        <h4 style={{ margin: "0", textAlign: "end", fontWeight: "bolder" }}>
          Price: (AUD){" "}
        </h4>
        <h4 style={{ margin: "0", textAlign: "end", fontWeight: "bolder" }}>
          Total: (AUD)
        </h4>
      </div>

      <Divider style={{ margin: "0.2rem 0" }} />
      {record?.quotationPackagesDetails?.map((pkg) => {
        return (
          <div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr 1fr 1fr",
              }}
            >
              <span>{pkg?.packageName}</span>
              <span
                style={{ margin: "0", textAlign: "end", paddingRight: "1rem" }}
              >
                {pkg?.quantity}
              </span>
              <span
                style={{ margin: "0", textAlign: "end", paddingRight: "1rem" }}
              >
                {pkg?.unitPrice}
              </span>
              <span
                style={{ margin: "0", textAlign: "end", paddingRight: "1rem" }}
              >
                {pkg?.totalAmount}
              </span>
            </div>
            <Divider style={{ margin: "0.2rem 0" }} />
          </div>
        );
      })}

      <div>
        <h4 style={{ margin: "0", textAlign: "end" }}>
          Discount:{" "}
          {record?.isPercentage
            ? record?.quotationPackagesDetails?.reduce(
                (a, b) => a + b.totalAmount,
                0
              ) *
              (record?.discount / 100)
            : record?.discount}{" "}
          AUD
        </h4>
        <Divider style={{ margin: "0.2rem 0" }} />
      </div>
      <div>
        <h4 style={{ margin: "0", textAlign: "end" }}>
          GST: {record?.gstAmount} %
        </h4>
        <Divider style={{ margin: "0.2rem 0" }} />
      </div>
      <div>
        <h4 style={{ margin: "0", textAlign: "end", fontWeight: "bolder" }}>
          Total Price:{" "}
          {/* {record?.quotationPackagesDetails?.reduce(
            (a, b) => a + b.totalAmount,
            0
          )}{" "} */}
          {record?.totalAmount}
          AUD
        </h4>
        <Divider style={{ margin: "0.2rem 0" }} />
      </div>
      <div>
        <h4 style={{ margin: "0" }}>
          Thank you for the opportunity to quote on the above project!
        </h4>
        <Divider style={{ margin: "0.2rem 0" }} />
      </div>
    </div>
  );
};
