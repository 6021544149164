import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Form,
  Input,
  Pagination,
  Popconfirm,
  Segmented,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  AiFillCopy,
  AiOutlineClockCircle,
  AiOutlineCloudUpload,
  AiOutlineCopy,
  AiOutlineEye,
  AiOutlineHistory,
  AiOutlineMail,
} from "react-icons/ai";
import { BsDownload } from "react-icons/bs";
import { CiMobile3 } from "react-icons/ci";
import { FiFlag } from "react-icons/fi";
import { GrHistory } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import { formattedDateTime } from "../../HelperFunction";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import store from "../../Redux/store";
import AddContacts from "../Contacts/AddContacts";
import AddEmployee from "../EmployeeManager/AddEmployee";
import AddAboutUs from "./AboutUs/AddAboutUs";
import AddLead from "./AddLead";
import BookAppointment from "./BookAppointment";
import AddConsultant from "./Consultant/AddConsultant";
import AddCountry from "./Country/AddCountry";
import LeadDetails from "./LeadDetails";
import AddLeadSource from "./LeadSource/AddLeadSource";
import AddLeadStatus from "./LeadStatus/AddLeadStatus";
import Purpose from "./Purpose/Purpose";
import AddReferral from "./Referral/AddReferral";
import StatusHistory from "./StatusHistory";
import UpdateLead from "./UpdateLead";
import { CSVLink, CSVDownload } from "react-csv";
import { async } from "rxjs";
import axios from "axios";
import { getCookie } from "./../../Helpers/FrontendHelper";
import SendComposedMail from "../Mailbox/SendComposedMail";
import Cookies from "js-cookie";
import moment from "moment";
const { Search } = Input;

const columns = [
  {
    title: "Lead ID",
    dataIndex: "id",

    align: "center",
  },
  {
    title: "Full Name",
    dataIndex: "fullname",
    sorter: (a, b) => {
      console.log(
        "form contact sorter a",
        a?.fullname?.props?.children?.props?.children?.props?.children
      );
      console.log("form contact sorter b", b);
      if (
        a?.fullname?.props?.children?.props?.children?.props?.children <
        b?.fullname?.props?.children?.props?.children?.props?.children
      ) {
        return -1;
      }
      if (
        a?.fullname?.props?.children?.props?.children?.props?.children >
        b?.fullname?.props?.children?.props?.children?.props?.children
      ) {
        return 1;
      }
      return 0;
    },
    align: "center",
  },
  {
    title: "Contact",
    dataIndex: "contactDetails",
    align: "center",
  },
  {
    title: "Purpose/Country",
    dataIndex: "purposeNameAndCountry",
    align: "center",
  },
  {
    title: "Source",
    dataIndex: "leadSourceName",
    sorter: (a, b) => {
      if (a.leadSourceName < b.leadSourceName) {
        return -1;
      }
      if (a.leadSourceName > b.leadSourceName) {
        return 1;
      }
      return 0;
    },
    align: "center",
  },
  {
    title: "Campaign",
    dataIndex: "campaign",
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "statusName",
    align: "center",
  },

  {
    title: "Added on",
    dataIndex: "addedDateAndTime",
    width: 150,
    align: "center",
  },
  {
    title: "Lead By",
    dataIndex: "leadBy",
    sorter: (a, b) => {
      if (a.leadBy < b.leadBy) {
        return -1;
      }
      if (a.leadBy > b.leadBy) {
        return 1;
      }
      return 0;
    },
    align: "center",
  },

  {
    title: "Actions",
    dataIndex: "actions",
    align: "center",
    fixed: "right",
  },
];
const Leads = ({ isHeadBranch, branchRecord }) => {
  const store = useSelector((state) => {
    return {
      leadState: state.LeadReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
    };
  });
  const [searchValue, setSearchValue] = useState("");
  const csvDownloadRef = useRef(null);

  const [isAddLeadVisible, setIsAddLeadVisible] = useState(false);
  const [isLeadsDetailsModelVisible, setIsLeadsDetailsModelVisible] =
    useState(false);
  const [isAddContactVisible, setIsAddContactVisible] = useState(false);
  const [isAddPurposeModalVisible, setIsAddPurposeModalVisible] =
    useState(false);
  const [isAddAboutUsModalVisible, setIsAddAboutUsModalVisible] =
    useState(false);
  const [isAddLeadSourceModalVisible, setIsAddLeadSourceModalVisible] =
    useState(false);
  const [isAddCountryModalVisible, setIsAddCountryModalVisible] =
    useState(false);
  const [isAddLeadStatusModalVisible, setIsAddLeadStatusModalVisible] =
    useState(false);
  const [isAddConsultantModalVisible, setIsAddConsultantModalVisible] =
    useState(false);
  const [isAddReferralModalVisible, setIsAddReferralModalVisible] =
    useState(false);
  const [isUpdateLeadModelVisible, setIsUpdateLeadModelVisible] =
    useState(false);
  const [isBookAppointmentModalVisible, setIsBookAppointmentModalVisible] =
    useState(false);
  const [isStatusHistoryModelVisible, setIsStatusHistoryModelVisible] =
    useState(false);
  const [isSendComposedEmailModalVisible, setIsSendComposedEmailModalVisible] =
    useState(false);
  const [leadsType, setLeadsType] = useState("allLeads");
  const [record, setRecord] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedVisible, setSelectedVisible] = useState(false);

  const [dataArray, setDataArray] = useState([]);
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };
  const onSearch = (e) => {
    if (leadsType === "allLeads") {
      dispatch({
        type: "SEARCH_LEADS_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    } else if (leadsType === "notAssignedLeads") {
      dispatch({
        type: "SEARCH_NOT_ASSIGNED_LEADS_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    } else if (leadsType === "inProgressLeads") {
      dispatch({
        type: "SEARCH_IN_PROGRESS_LEADS_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
          leadsType: "IN_PROGRESS",
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    } else if (leadsType === "completedLeads") {
      dispatch({
        type: "SEARCH_COMPLETED_LEADS_REQUEST",
        payload: {
          page: 1,
          pageSize: 10,
          searchKey: { keyword: e },
          leadsType: "COMPLETED",
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    }
  };
  const [leadData, setLeadData] = useState({
    leadPrefix: "",
    leadReminder: "",
    appointmentPerfix: "",
    appointmentReminder: "",
  });
  const successFunction = (response) => {
    console.log("response from reminder expiry date", response);
    setLeadData((previousData) => {
      return {
        ...previousData,
        leadPrefix: response?.data?.prefix?.leadPrefix,
        leadReminder: response?.data?.expiryDates?.leadReminderDateAfter,
        appointmentPerfix: response?.data?.prefix?.appointmentPrefix,
        appointmentReminder:
          response?.data?.expiryDates?.appointmentReminderDateAfter,
      };
    });
  };

  useEffect(() => {
    dispatch({
      type: "FETCH_REMINDER_EXIPRY_WORKING_HOUR_REQUEST",
      isSuccessFn: true,
      successFunction: successFunction,
    });
    if (leadsType === "allLeads") {
      dispatch({
        type: "FETCH_LEADS_REQUEST",
        payload: {
          page,
          pageSize,
          branchId: branchRecord.id ?? localStorage.getItem("branchId"),
        },
      });
    } else if (leadsType === "notAssignedLeads") {
      dispatch({
        type: "FETCH_NOT_ASSIGNED_LEADS_REQUEST",
        payload: {
          page,
          pageSize,
          branchId: branchRecord.id ?? localStorage.getItem("branchId"),
        },
      });
    } else if (leadsType === "inProgressLeads") {
      dispatch({
        type: "FETCH_IN_PROGRESS_LEADS_REQUEST",
        payload: {
          page,
          pageSize,
          branchId: branchRecord.id ?? localStorage.getItem("branchId"),
        },
      });
    } else if (leadsType === "completedLeads") {
      dispatch({
        type: "FETCH_COMPLETED_LEADS_REQUEST",
        payload: {
          page,
          pageSize,
          branchId: branchRecord.id ?? localStorage.getItem("branchId"),
        },
      });
    }
  }, [leadsType, page, pageSize, store.branchState?.branchRecord]);

  useEffect(() => {
    if (isAddLeadVisible === false || isUpdateLeadModelVisible === false) {
      dispatch({
        type: "FETCH_LEADS_REQUEST",
        payload: { page, pageSize, isHeadBranch, branchId: branchRecord.id },
      });
      dispatch({ type: "FETCH_LEADS_DROPDOWN_REQUEST" });
    }
  }, [isAddLeadVisible, isUpdateLeadModelVisible]);
  useEffect(() => {
    if (
      dataArray &&
      csvDownloadRef &&
      csvDownloadRef.current &&
      csvDownloadRef.current.link
    ) {
      csvDownloadRef.current.link.click();
    }
  }, [dataArray]);

  let data = [];
  if (leadsType === "allLeads") {
    data = store?.leadState.leads?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        id: dataObj.id ? `${leadData.leadPrefix ?? ""}${dataObj.id}` : "N/A",
        contactDetails: (
          <div className="flexColumnWithoutStyle">
            {dataObj.email !== "" && dataObj.email !== null ? (
              <Tooltip title="Send Email">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsSendComposedEmailModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <div
                      className="flexRowWithoutStyle"
                      style={{ gap: "2px", color: "000", color: "000" }}
                    >
                      <AiOutlineMail />
                      <a>{dataObj.email ?? "N/A"}</a>
                    </div>
                  </div>
                </a>
              </Tooltip>
            ) : null}
            {dataObj.mobile !== "" && dataObj.mobile !== null ? (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "2px", color: "000" }}
              >
                <CiMobile3 />
                <a href={`tel:${dataObj.mobile}`}>{dataObj.mobile ?? "N/A"}</a>
              </div>
            ) : null}
          </div>
        ),
        purposeNameAndCountry: (
          <div className="flexColumnWithoutStyle">
            <div
              className="flexRowWithoutStyle"
              style={{
                gap: "2px",
                justifyContent: "flex-start",
                // border: "1px solid red",
                color: "000",
                color: "000",
              }}
            >
              <AiOutlineCopy
                style={{ fontSize: "15px", height: "15px", width: "15px" }}
              />
              <span>{dataObj.purposeName ?? "N/A"}</span>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "2px", color: "000" }}
            >
              <FiFlag />
              {dataObj.countryName ?? "N/A"}
            </div>
          </div>
        ),
        fullname: (
          <Tooltip title="Leads Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsLeadsDetailsModelVisible(true);
                  setRecord(dataObj);
                }}
              >
                {" "}
                {dataObj.firstName ?? ""} {dataObj.lastName ?? ""}
              </div>
            </a>
          </Tooltip>
        ),
        email: dataObj.email ? dataObj.email : "N/A",
        mobile: dataObj.mobile ? dataObj.mobile : "N/A",
        leadSourceName: dataObj.leadSourceName ? dataObj.leadSourceName : "N/A",
        statusName: (
          <>
            {dataObj.leadStatus?.statusName === "BOOK_AN_APPOINTMENT" ? (
              <Tag color={dataObj.leadStatus?.statusColorCode}>
                BOOK AN APPOINTMENT
              </Tag>
            ) : (
              <Tag color={dataObj.leadStatus?.statusColorCode ?? "blue"}>
                {dataObj.leadStatus?.statusName}
              </Tag>
            )}
          </>
        ),
        leadBy: dataObj.leadBy ? dataObj.leadBy : "N/A",
        // addedDateAndTime: dataObj.addedDate
        //   ? formattedDateTime(dataObj.addedDate)
        //   : "N/A",
        addedDateAndTime: dataObj?.addedDate
          ? moment(dataObj?.addedDate).format("MM/DD/YYYY HH-mm-ss")
          : null,
        campaign: dataObj?.customerResponse?.campaign?.name
          ? dataObj?.customerResponse?.campaign?.name
          : dataObj.campaign,

        actions: (
          <Space size="middle">
            {store.profileState?.profile?.department?.rolesAndPermissionList?.LEADS?.includes(
              "UPDATE"
            ) && (
              <Tooltip title="Update Lead">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateLeadModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}

            <Tooltip title="Leads Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsLeadsDetailsModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
          </Space>
        ),
      };
    });
  } else if (leadsType === "notAssignedLeads") {
    data = store?.leadState.notAssignedLeads?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        id: `${leadData.leadPrefix ?? ""}${dataObj.id}`,
        contactDetails: (
          <div className="flexColumnWithoutStyle">
            {dataObj.email !== "" && dataObj.email !== null ? (
              <Tooltip title="Send Email">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsSendComposedEmailModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <div
                      className="flexRowWithoutStyle"
                      style={{ gap: "2px", color: "000", color: "000" }}
                    >
                      <AiOutlineMail />
                      <a>{dataObj.email ?? "N/A"}</a>
                    </div>
                  </div>
                </a>
              </Tooltip>
            ) : null}
            {dataObj.mobile !== "" && dataObj.mobile !== null ? (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "2px", color: "000" }}
              >
                <CiMobile3 />
                <a href={`tel:${dataObj.mobile}`}>{dataObj.mobile ?? "N/A"}</a>
              </div>
            ) : null}
          </div>
        ),
        purposeNameAndCountry: (
          <div className="flexColumnWithoutStyle">
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "2px", color: "000", color: "000" }}
            >
              <AiOutlineCopy />
              {dataObj.purposeName ?? "N/A"}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "2px", color: "000" }}
            >
              <FiFlag />
              {dataObj.countryName ?? "N/A"}
            </div>
          </div>
        ),
        fullname: (
          <Tooltip title="Leads Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsLeadsDetailsModelVisible(true);
                  setRecord(dataObj);
                }}
              >
                {" "}
                {dataObj.firstName ?? ""} {dataObj.lastName ?? ""}
              </div>
            </a>
          </Tooltip>
        ),
        email: dataObj.email ?? "N/A",
        mobile: dataObj.mobile ?? "N/A",
        leadSourceName: dataObj.leadSourceName ?? "N/A",
        statusName: (
          <>
            {dataObj.leadStatus?.statusName === "BOOK_AN_APPOINTMENT" ? (
              <Tag color={dataObj.leadStatus?.statusColorCode}>IN PROGRESS</Tag>
            ) : (
              <Tag color={dataObj.leadStatus?.statusColorCode}>
                {dataObj.leadStatus?.statusName}
              </Tag>
            )}
          </>
        ),
        leadBy: dataObj.leadBy ?? "N/A",
        addedDateAndTime: formattedDateTime(dataObj.addedDate),
        campaign: dataObj?.customerResponse?.campaign?.name
          ? dataObj?.customerResponse?.campaign?.name
          : dataObj.campaign,

        actions: (
          <Space size="middle">
            <Tooltip title="Update Lead">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsUpdateLeadModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip>

            {/* <Tooltip title="Book an appointment">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsBookAppointmentModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineClockCircle />
                </div>
              </a>
            </Tooltip> */}
            <Tooltip title="Leads Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsLeadsDetailsModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            <Tooltip title="Delete Lead">
              <Popconfirm
                placement="topRight"
                title="Are you sure to delete this lead?"
                onConfirm={() => {
                  dispatch({
                    type: "DELETE_LEAD_REQUEST",
                    payload: { id: dataObj.id },
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined style={{ color: "red" }} />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip>
            {/* {isHeadBranch && (
              <Tooltip title="View status History">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsStatusHistoryModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <AiOutlineHistory />
                  </div>
                </a>
              </Tooltip>
            )} */}
          </Space>
        ),
      };
    });
  } else if (leadsType === "inProgressLeads") {
    data = store?.leadState.inProgressLeads?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        id: `${leadData.leadPrefix ?? ""}${dataObj.id}`,
        contactDetails: (
          <div className="flexColumnWithoutStyle">
            {dataObj.email !== "" && dataObj.email !== null ? (
              <Tooltip title="Send Email">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsSendComposedEmailModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <div
                      className="flexRowWithoutStyle"
                      style={{ gap: "2px", color: "000", color: "000" }}
                    >
                      <AiOutlineMail />
                      <a>{dataObj.email ?? "N/A"}</a>
                    </div>
                  </div>
                </a>
              </Tooltip>
            ) : null}
            {dataObj.mobile !== "" && dataObj.mobile !== null ? (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "2px", color: "000" }}
              >
                <CiMobile3 />
                <a href={`tel:${dataObj.mobile}`}>{dataObj.mobile ?? "N/A"}</a>
              </div>
            ) : null}
          </div>
        ),
        purposeNameAndCountry: (
          <div className="flexColumnWithoutStyle">
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "2px", color: "000", color: "000" }}
            >
              <AiOutlineCopy />
              {dataObj.purposeName ?? "N/A"}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "2px", color: "000" }}
            >
              <FiFlag />
              {dataObj.countryName ?? "N/A"}
            </div>
          </div>
        ),
        fullname: (
          <Tooltip title="Leads Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsLeadsDetailsModelVisible(true);
                  setRecord(dataObj);
                }}
              >
                {" "}
                {dataObj.firstName ?? ""} {dataObj.lastName ?? ""}
              </div>
            </a>
          </Tooltip>
        ),
        email: dataObj.email ?? "N/A",
        mobile: dataObj.mobile ?? "N/A",
        leadSourceName: dataObj.leadSourceName,
        statusName: (
          <>
            {dataObj.leadStatus?.statusName === "BOOK_AN_APPOINTMENT" ? (
              <Tag color={dataObj.leadStatus?.statusColorCode}>IN PROGRESS</Tag>
            ) : (
              <Tag color={dataObj.leadStatus?.statusColorCode}>
                {dataObj.leadStatus?.statusName}
              </Tag>
            )}
          </>
        ),
        leadBy: dataObj.leadBy ?? "N/A",
        addedDateAndTime: dataObj.addedDate
          ? formattedDateTime(dataObj.addedDate)
          : "N/A",
        campaign: dataObj?.customerResponse?.campaign?.name
          ? dataObj?.customerResponse?.campaign?.name
          : dataObj.campaign,

        actions: (
          <Space size="middle">
            <Tooltip title="Update Lead">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsUpdateLeadModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip>

            {/* <Tooltip title="Delete Lead">
              <Popconfirm
                title="Are you sure to delete this lead?"
                onConfirm={() => {
                  dispatch({
                    type: "DELETE_LEAD_REQUEST",
                    payload: { id: dataObj.id },
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip> */}
            {/* <Tooltip title="Book an appointment">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsBookAppointmentModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineClockCircle />
                </div>
              </a>
            </Tooltip> */}
            <Tooltip title="Leads Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsLeadsDetailsModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            {/* {isHeadBranch && (
              <Tooltip title="View status History">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsStatusHistoryModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <AiOutlineHistory />
                  </div>
                </a>
              </Tooltip>
            )} */}
          </Space>
        ),
      };
    });
  } else if (leadsType === "completedLeads") {
    data = store?.leadState.completedLeads?.data?.map((dataObj) => {
      return {
        key: dataObj.id,
        id: `${leadData.leadPrefix ?? ""}${dataObj.id}`,
        contactDetails: (
          <div className="flexColumnWithoutStyle">
            {dataObj.email !== "" && dataObj.email !== null ? (
              <Tooltip title="Send Email">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsSendComposedEmailModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <div
                      className="flexRowWithoutStyle"
                      style={{ gap: "2px", color: "000", color: "000" }}
                    >
                      <AiOutlineMail />
                      <a>{dataObj.email ?? "N/A"}</a>
                    </div>
                  </div>
                </a>
              </Tooltip>
            ) : null}
            {dataObj.mobile !== "" && dataObj.mobile !== null ? (
              <div
                className="flexRowWithoutStyle"
                style={{ gap: "2px", color: "000" }}
              >
                <CiMobile3 />
                <a href={`tel:${dataObj.mobile}`}>{dataObj.mobile ?? "N/A"}</a>
              </div>
            ) : null}
          </div>
        ),
        purposeNameAndCountry: (
          <div className="flexColumnWithoutStyle">
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "2px", color: "000", color: "000" }}
            >
              <AiOutlineCopy />
              {dataObj.purposeName ?? "N/A"}
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "2px", color: "000" }}
            >
              <FiFlag />
              {dataObj.countryName ?? "N/A"}
            </div>
          </div>
        ),
        fullname: (
          <Tooltip title="Leads Details">
            <a>
              <div
                className="bordered"
                onClick={() => {
                  setIsLeadsDetailsModelVisible(true);
                  setRecord(dataObj);
                }}
              >
                {" "}
                {dataObj.firstName ?? ""} {dataObj.lastName ?? ""}
              </div>
            </a>
          </Tooltip>
        ),
        email: dataObj.email ?? "N/A",
        mobile: dataObj.mobile ?? "N/A",
        leadSourceName: dataObj.leadSourceName ?? "N/A",
        statusName: (
          <>
            {dataObj.leadStatus?.statusName === "BOOK_AN_APPOINTMENT" ? (
              <Tag color={dataObj.leadStatus?.statusColorCode}>IN PROGRESS</Tag>
            ) : (
              <Tag color={dataObj.leadStatus?.statusColorCode}>
                {dataObj.leadStatus?.statusName}
              </Tag>
            )}
          </>
        ),
        leadBy: dataObj.leadBy ?? "N/A",
        addedDateAndTime: dataObj.addedDate
          ? formattedDateTime(dataObj.addedDate)
          : "N/A",
        campaign: dataObj?.customerResponse?.campaign?.name
          ? dataObj?.customerResponse?.campaign?.name
          : dataObj.campaign,

        actions: (
          <Space size="middle">
            <Tooltip title="Update Lead">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsUpdateLeadModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <EditOutlined />
                </div>
              </a>
            </Tooltip>

            {/* <Tooltip title="Delete Lead">
              <Popconfirm
                title="Are you sure to delete this lead?"
                onConfirm={() => {
                  dispatch({
                    type: "DELETE_LEAD_REQUEST",
                    payload: { id: dataObj.id },
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip> */}
            {/* <Tooltip title="Book an appointment">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsBookAppointmentModalVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineClockCircle />
                </div>
              </a>
            </Tooltip> */}
            <Tooltip title="Leads Details">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setIsLeadsDetailsModelVisible(true);
                    setRecord(dataObj);
                  }}
                >
                  <AiOutlineEye />
                </div>
              </a>
            </Tooltip>
            {/* {isHeadBranch && (
              <Tooltip title="View status History">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsStatusHistoryModelVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <AiOutlineHistory />
                  </div>
                </a>
              </Tooltip>
            )} */}
          </Space>
        ),
      };
    });
  }
  const onSelectChange = (e, f, g) => {
    setSelectedRowKeys(e);
    setSelectedVisible(true);
  };
  const CSVSuccessFunction = (response) => {
    console.log("csv response task", response);
    const dataArray = response?.data?.split("\n").map((row) => row.split(","));
    setDataArray(dataArray);
    console.log("dataArray", dataArray, "legth");
    // csvDownloadRef.current?.link?.click();
    console.log("csvDownloadRef.current", csvDownloadRef);
    // return <CSVDownload data={dataArray} target="_blank" />;
  };

  console.log("From leads", store?.leadState);
  console.log(
    "profile permission",
    store.profileState?.profile?.department?.rolesAndPermissionList?.LEADS
  );
  console.log("From lead store?.leadState.leadCsv", store?.leadState?.leadCsv);

  return (
    <SiderDemo>
      <div>
        <div className="leadFlexRow">
          <div style={{ width: "30%" }}>
            {store.profileState?.profile?.department?.rolesAndPermissionList?.LEADS?.includes(
              "ADD"
            ) && (
              <button
                className="button"
                onClick={() => {
                  setIsAddLeadVisible(true);
                }}
              >
                <span>Add Lead</span>
              </button>
            )}
          </div>
          <Segmented
            style={{ width: "40%" }}
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>ALL</div>
                  </div>
                ),
                value: "allLeads",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>NOT ASSIGNED</div>
                  </div>
                ),
                value: "notAssignedLeads",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>IN PROGRESS</div>
                  </div>
                ),
                value: "inProgressLeads",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>COMPLETED</div>
                  </div>
                ),
                value: "completedLeads",
              },
            ]}
            onChange={(value) => {
              setPage(1);
              setPageSize(10);
              setLeadsType(value);
              console.log(value);
            }}
          />
          <div
            style={{
              display: "flex",
              width: "30%",
              alignItems: "center",
              gap: "0.3rem",
            }}
          >
            <Search
              className="search"
              style={{
                width: "100%",
                borderRadius: "10px ! important",
              }}
              placeholder="Search for leads"
              allowClear
              size="large"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                onSearch(e.target.value);
              }}
            />
            {/* <div>
              <Tooltip title={"Upload CSV"} placement={"topLeft"}>
                <input
                  type={"file"}
                  name="file1"
                  style={{ display: "none" }}
                  id="file1"
                  onChange={(e) => {
                    console.log("from CSV file change", e);
                    const importCSVFormData = new FormData();
                    importCSVFormData.append("file", e.target.files[0]);
                    dispatch({
                      type: "IMPORT_LEAD_CSV_FILE_REQUEST",
                      payload: importCSVFormData,
                    });
                  }}
                />
                <label for="file1">
                  <div
                    className="flexCenter"
                    style={{
                      border: "1px solid #d9d9d9",
                      borderRadius: "50%",
                      padding: "0.3rem",
                      height: "2rem",
                      width: "2rem",
                    }}
                  >
                    <AiOutlineCloudUpload style={{ fontSize: "20px" }} />
                  </div>
                </label>
              </Tooltip>
            </div> */}
          </div>
          {/* <Search
            enterButton={false}
            placeholder="input search text"
            allowClear
            onSearch={(e) => {
              console.log("leads search", e);
            }}
            style={{
              width: 200,
            }}
          /> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          {store?.leadState.isLoading ? (
            <TableSkeleton />
          ) : (
            <>
              {selectedRowKeys.length > 0 && selectedVisible && (
                <Alert
                  message={`Selected ${selectedRowKeys.length} items.`}
                  type="success"
                  closable
                  afterClose={() => {
                    setSelectedVisible(false);
                    setSelectedRowKeys([]);
                  }}
                  action={
                    <>
                      {store.profileState?.profile?.department?.rolesAndPermissionList?.LEADS?.includes(
                        "DOWNLOAD_CSV"
                      ) && (
                        <Tooltip title={"Download CSV"}>
                          <Button
                            size="small"
                            type="primary"
                            onClick={(e) => {
                              const csvPayload = {
                                leadIds: selectedRowKeys?.map((dataObj) => {
                                  return {
                                    id: dataObj,
                                  };
                                }),
                              };
                              console.log("customerIds", csvPayload);

                              dispatch({
                                type: "DOWNLOAD_LEAD_CSV_REQUEST",
                                payload: csvPayload,
                                fromLead: true,
                                CSVSuccessFunction,
                              });
                            }}
                          >
                            Download CSV
                          </Button>
                        </Tooltip>
                      )}
                      <CSVLink
                        ref={csvDownloadRef}
                        asyncOnClick={true}
                        data={dataArray}
                        target="_blank"
                        filename="LeadCSV.csv"
                      />
                    </>
                  }
                />
              )}
              <Table
                bordered
                rowSelection={{
                  selectedRowKeys,
                  onChange: onSelectChange,
                }}
                columns={columns}
                dataSource={data}
                pagination={false}
              />
            </>
          )}
          {store?.leadState.leads?.totalData ? (
            <Pagination
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                alignSelf: "flex-end",
              }}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={1}
              current={page}
              defaultPageSize={pageSize}
              onChange={onShowSizeChange}
              total={store?.leadState.leads?.totalPage * 10}
            />
          ) : null}
        </div>
      </div>
      {isAddLeadVisible && (
        <AddLead
          leadReminderData={leadData}
          isAddLeadVisible={isAddLeadVisible}
          setIsAddLeadVisible={setIsAddLeadVisible}
          // setIsAddContactVisible={setIsAddContactVisible}
          // setIsAddAboutUsModalVisible={setIsAddAboutUsModalVisible}
          // setIsAddCountryModalVisible={setIsAddCountryModalVisible}
          // setIsAddLeadSourceModalVisible={setIsAddLeadSourceModalVisible}
          // setIsAddLeadStatusModalVisible={setIsAddLeadStatusModalVisible}
          // setIsAddPurposeModalVisible={setIsAddPurposeModalVisible}
          // isAddConsultantModalVisible={isAddConsultantModalVisible}
          // setIsAddConsultantModalVisible={setIsAddConsultantModalVisible}
          // isAddReferralModalVisible={isAddReferralModalVisible}
          // setIsAddReferralModalVisible={setIsAddReferralModalVisible}
        />
      )}
      {isUpdateLeadModelVisible && (
        <UpdateLead
          isUpdateLeadModelVisible={isUpdateLeadModelVisible}
          setIsUpdateLeadModelVisible={setIsUpdateLeadModelVisible}
          record={record}
          isAddContactVisible={isAddContactVisible}
          setIsAddContactVisible={setIsAddContactVisible}
          isAddAboutUsModalVisible={isAddAboutUsModalVisible}
          setIsAddAboutUsModalVisible={setIsAddAboutUsModalVisible}
          isAddLeadSourceModalVisible={isAddLeadSourceModalVisible}
          setIsAddCountryModalVisible={setIsAddCountryModalVisible}
          setIsAddLeadSourceModalVisible={setIsAddLeadSourceModalVisible}
          isAddLeadStatusModalVisible={isAddLeadStatusModalVisible}
          setIsAddLeadStatusModalVisible={setIsAddLeadStatusModalVisible}
          isAddPurposeModalVisible={isAddPurposeModalVisible}
          setIsAddPurposeModalVisible={setIsAddPurposeModalVisible}
          isAddConsultantModalVisible={isAddConsultantModalVisible}
          setIsAddConsultantModalVisible={setIsAddConsultantModalVisible}
          isAddReferralModalVisible={isAddReferralModalVisible}
          setIsAddReferralModalVisible={setIsAddReferralModalVisible}
        />
      )}
      {isLeadsDetailsModelVisible && (
        <LeadDetails
          leadData={leadData}
          Record={record}
          isLeadsDetailsModelVisible={isLeadsDetailsModelVisible}
          setIsLeadsDetailsModelVisible={setIsLeadsDetailsModelVisible}
        />
      )}
      {isBookAppointmentModalVisible && (
        <BookAppointment
          record={record}
          isBookAppointmentModalVisible={isBookAppointmentModalVisible}
          setIsBookAppointmentModalVisible={setIsBookAppointmentModalVisible}
        />
      )}

      {isAddPurposeModalVisible && (
        <Purpose
          isAddPurposeModalVisible={isAddPurposeModalVisible}
          setIsAddPurposeModalVisible={setIsAddPurposeModalVisible}
        />
      )}
      {isAddAboutUsModalVisible && (
        <AddAboutUs
          isAddAboutUsModalVisible={isAddAboutUsModalVisible}
          setIsAddAboutUsModalVisible={setIsAddAboutUsModalVisible}
        />
      )}
      {isAddCountryModalVisible && (
        <AddCountry
          isAddCountryModalVisible={isAddCountryModalVisible}
          setIsAddCountryModalVisible={setIsAddCountryModalVisible}
        />
      )}
      {isAddLeadSourceModalVisible && (
        <AddLeadSource
          isAddLeadSourceModalVisible={isAddLeadSourceModalVisible}
          setIsAddLeadSourceModalVisible={setIsAddLeadSourceModalVisible}
        />
      )}
      {isAddLeadStatusModalVisible && (
        <AddLeadStatus
          isAddLeadStatusModalVisible={isAddLeadStatusModalVisible}
          setIsAddLeadStatusModalVisible={setIsAddLeadStatusModalVisible}
        />
      )}
      {isAddConsultantModalVisible && (
        <AddEmployee
          AddEmployeeTitle={"Add Consultant"}
          isConsultantFrmConsultant={true}
          isAddEmployeeModalVisible={isAddConsultantModalVisible}
          setIsAddEmployeeModalVisible={setIsAddConsultantModalVisible}
        />
      )}
      {isAddReferralModalVisible && (
        <AddReferral
          isAddReferralModalVisible={isAddReferralModalVisible}
          setIsAddReferralModalVisible={setIsAddReferralModalVisible}
        />
      )}
      {isStatusHistoryModelVisible && (
        <StatusHistory
          type={"LEAD"}
          record={record}
          isStatusHistoryModalVisible={isStatusHistoryModelVisible}
          setIsStatusHistoryModalVisible={setIsStatusHistoryModelVisible}
        />
      )}
      {isSendComposedEmailModalVisible && (
        <SendComposedMail
          mailTemplateType="LEAD"
          composeMailRecord={{
            toUser: [record?.email],
          }}
          isSendComposedEmailModalVisible={isSendComposedEmailModalVisible}
          setIsSendComposedEmailModalVisible={
            setIsSendComposedEmailModalVisible
          }
        />
      )}
    </SiderDemo>
  );
};

export default Leads;
