import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
  Radio,
  Tag,
  Divider,
} from "antd";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { dateAndTime, scrollToTopFormError } from "../../HelperFunction";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
const { Option } = Select;
const { TextArea } = Input;

const UpdateTask = ({
  record,
  isUpdateTaskModalVisible,
  setIsUpdateTaskModalVisible,
  taskType = "",
  updateType = "",
  fetchClientById,
  fetchCaseDetail,
  fetchCompanyByID,
  fetchInstituteById,
}) => {
  const UpdateTaskState = useSelector((state) => state.VisasReducer);
  const VisaState = useSelector((store) => store?.VisasReducer);

  console.log("testing testing", record);

  const [openMore, setOpenMore] = useState(false);
  const [labelName, setLabelName] = useState(taskType ? taskType : "Client");
  const [activeList, setActiveList] = useState([]);
  const [employee, setEmployee] = useState(
    VisaState?.taskDropdown?.employees ? VisaState?.taskDropdown?.employees : []
  );
  const [taskRelateTo, setTaskRealteTo] = useState(
    record?.taskRelateTo === "CASE"
      ? "Case"
      : record?.taskRelateTo === "CLIENT"
      ? "Client"
      : record?.taskRelateTo === "COMPANY"
      ? "Company"
      : record?.taskRelateTo === "INSTITUTE"
      ? "Institute"
      : null
  );
  const [taskManager, setTaskManager] = useState(
    record?.taskManagerResponse
      ? record?.taskManagerResponse?.map((data) => data?.employeeId)
      : []
  );
  const [taskName, setTaskName] = useState(
    record?.taskName ? parseInt(record?.taskName) : null
  );

  useEffect(() => {
    dispatch({
      type: "FETCH_TASK_DROPDOWN_REQUEST",
      payload: localStorage.getItem("branchId"),
      isSuccessFn: true,
      successFunction: (res) => {
        if (record?.taskRelateTo === "CASE") {
          setActiveList(res?.data?.cases);
          setLabelName("Case");
        } else if (record?.taskRelateTo === "CLIENT") {
          setActiveList(res?.data?.clients);
          setLabelName("Client");
        } else if (record?.taskRelateTo === "INSTITUTE") {
          setActiveList(res?.data?.institutes);
          setLabelName("Institute");
        } else if (record?.taskRelateTo === "COMPANY") {
          setActiveList(res?.data?.companies);
          setLabelName("Company");
        }
        setEmployee(res?.data?.employees);
      },
    });
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isUpdateTaskModalVisible === true) {
      dispatch({
        type: "FETCH_CASES_DROPDOWN_REQUEST",
        payload: {
          branchId: localStorage.getItem("branchId"),
        },
      });
    }
  }, [isUpdateTaskModalVisible]);

  const [assignedTo, setAssignedTo] = useState(
    record?.assignedToResponse?.length > 0 &&
      record?.assignedToResponse?.map((assign) => assign?.employeeId)
  );

  const [form] = Form.useForm();
  const updateTaskSubmitHandler = (value) => {
    const startDate = dateAndTime(value.startDate);
    const dueDate = dateAndTime(value.dueDate);
    const formData = {
      startDate,
      dueDate,
      ...value,
      assignedTo: assignedTo,
      taskRelateTo: taskRelateTo,
      taskManager: taskManager,
      taskName: taskName,
    };
    dispatch({
      type: "UPDATE_TASK_REQUEST",
      payload: { id: record.id, values: formData },
      isSuccessFn: true,
      successFunction: () => {
        if (updateType === "client") {
          fetchClientById();
        } else if (updateType === "case") {
          fetchCaseDetail();
        } else if (updateType === "company") {
          fetchCompanyByID();
        } else if (updateType === "institute") {
          fetchInstituteById();
        }
      },
    });
    // form.resetFields();
    setIsUpdateTaskModalVisible(false);
  };

  return (
    <Drawer
      title="Update Task"
      open={isUpdateTaskModalVisible}
      onClose={() => {
        setIsUpdateTaskModalVisible(false);
      }}
      footer={null}
      width={1163}
    >
      <Form
        layout="vertical"
        onFinish={updateTaskSubmitHandler}
        onFinishFailed={scrollToTopFormError}
        colon={true}
        form={form}
        className="drawerStyle"
        initialValues={{
          assignedToEmail: record?.assignedToResponse?.map(
            (assign) => assign?.email
          ),

          taskManager: record?.taskManagerResponse?.map(
            (data) => data?.employeeId
          ),
          // taskName: record?.taskName ? parseInt(record?.taskName) : null,
          // taskName: record?.taskName,

          taskRelateTo:
            record?.taskRelateTo === "CASE"
              ? "Case"
              : record?.taskRelateTo === "CLIENT"
              ? "Client"
              : record?.taskRelateTo === "COMPANY"
              ? "Company"
              : record?.taskRelateTo === "INSTITUTE"
              ? "Institute"
              : null,
        }}
        fields={[
          {
            name: ["taskTitle"],
            value: record.taskTitle,
          },
          {
            name: ["taskStatus"],
            value: record.taskStatus,
          },
          // {
          //   name: ["assignedTo"],
          //   value: record?.assignedTo?.id,
          // },
          {
            name: ["priority"],
            value: record.priority,
          },
          {
            name: ["startDate"],
            value: record.startDate
              ? moment.utc(record.startDate).local()
              : null,
          },
          {
            name: ["dueDate"],
            value: record.dueDate ? moment.utc(record.dueDate).local() : null,
          },
          {
            name: ["updateNote"],
            value: record.updateNote,
          },
          {
            name: ["taskDescription"],
            value: record.taskDescription,
          },
          {
            name: ["taskName"],
            value: record?.taskName ? parseInt(record?.taskName) : null,
          },
        ]}
        // initialValues={{ startDate: moment() }}
      >
        <Form.Item
          label="Task Title"
          name={"taskTitle"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter task!",
            },
          ]}
        >
          <Input placeholder="Task Title" />
        </Form.Item>
        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Start Date"
            name={"startDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick start date!",
              },
            ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              disabledDate={(cd) => {
                const d = new Date();
                return cd < d;
              }}
            />
          </Form.Item>
          <Form.Item
            label="Due Date"
            name={"dueDate"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please pick a due date",
              },
            ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              disabledDate={(cd) => {
                const d = new Date();
                return cd < d;
              }}
            />
          </Form.Item>
        </div>
        <div
          className="flexRowWithoutStyle"
          style={{
            justifyContent: "space-between",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          {/* <Form.Item
            label="assignedBy"
            name={"assignedBy"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter",
              },
            ]}
          >
            <Select>
              {UpdateTaskState.leadsDropdown?.consultantList?.map((dataObj) => {
                return <Option key={dataObj.id}>{dataObj.emailId}</Option>;
              })}
            </Select>
          </Form.Item> */}
          <Form.Item
            label="Assigned To"
            name={"assignedToEmail"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter assignedTo!",
              },
            ]}
          >
            <Select
              mode="multiple"
              onChange={(value, option) => {
                setAssignedTo(option?.map((keyobj) => parseInt(keyobj?.key)));
              }}
            >
              {UpdateTaskState.caseDropdown?.consultancyEmployee?.map(
                (dataObj) => {
                  return (
                    <Option key={dataObj.id} value={dataObj.emailId}>
                      {dataObj.emailId}
                    </Option>
                  );
                }
              )}
            </Select>
          </Form.Item>
          {/* <Form.Item
            name={"assignToMyself"}
            valuePropName="checked"
            style={{ width: "100%" }}
          >
            <Checkbox>Assign to Myself</Checkbox>
          </Form.Item> */}
        </div>

        <div
          className="flexRowWithoutStyle"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <Form.Item
            label="Task Status"
            name={"taskStatus"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please select taskStatus!",
              },
            ]}
          >
            <Select>
              <Option value={"PENDING"}>Pending</Option>
              <Option value={"ASSIGNED"}>Assigned</Option>
              <Option value={"COMPLETED"}>Completed</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Priority"
            name={"priority"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter priority!",
              },
            ]}
          >
            <Select>
              <Option value={"NORMAL"}>Normal</Option>
              <Option value={"HIGH"}>High</Option>
              <Option value={"URGENT"}>Urgent</Option>
            </Select>
          </Form.Item>
        </div>

        <Form.Item
          label="Task Description"
          name={"taskDescription"}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Please enter !",
            },
          ]}
        >
          <TextArea placeholder="Write about task" />
        </Form.Item>
        <Form.Item
          label="Update Note"
          name={"updateNote"}
          style={{ width: "100%" }}
          // rules={[
          //   {
          //     required: true,
          //     message: "Please enter updateNote!",
          //   },
          // ]}
        >
          <TextArea placeholder="Write recent update" />
        </Form.Item>

        {!openMore && (
          <div style={{ margin: "0 0 1rem" }}>
            <Divider style={{ margin: 0 }} />
            <Button
              type="outlined"
              size="small"
              icon={<DownOutlined />}
              onClick={() => {
                setOpenMore(true);
              }}
            >
              View More
            </Button>
          </div>
        )}

        {openMore && (
          <div>
            <Form.Item label="Related To" name="taskRelateTo">
              <Radio.Group
                onChange={(e) => {
                  setTaskName(e.target.value);
                  setLabelName(e.target.value);
                  form.setFieldsValue({
                    partnerName: null,
                    projectManager: [],
                  });

                  if (e.target.value === "Client") {
                    setActiveList(VisaState?.taskDropdown?.clients);
                  } else if (e.target.value === "Case") {
                    setActiveList(VisaState?.taskDropdown?.cases);
                  } else if (e.target.value === "Institute") {
                    setActiveList(VisaState?.taskDropdown?.institutes);
                  } else if (e.target.value === "Company") {
                    setActiveList(VisaState?.taskDropdown?.companies);
                  }
                }}
              >
                <Radio value="Client">Client</Radio>
                <Radio value="Case">Case</Radio>
                <Radio value="Institute">Institute</Radio>
                <Radio value="Company">Company</Radio>
                <Radio value="Inhouse">Inhouse</Radio>
              </Radio.Group>
            </Form.Item>
            {/* {labelName !== "Inhouse" && ( */}
            <Form.Item name="taskName" label={`${labelName} Name`}>
              <Select
                showSearch
                placeholder="Select Partner Name"
                onChange={(value) => {
                  setTaskName(value);
                }}
              >
                {record?.taskRelateTo === "CLIENT" &&
                  activeList?.map((data) => (
                    <Select.Option value={data?.id}>
                      {data?.email} ({data?.fullName})
                    </Select.Option>
                  ))}

                {record?.taskRelateTo === "CASE" &&
                  activeList?.map((data) => (
                    <Select.Option value={data?.id}>
                      {data?.customer?.email} ({data?.customer?.fullName})
                      <Tag color="blue">KH{data?.id}</Tag>
                    </Select.Option>
                  ))}
                {record?.taskRelateTo === "INSTITUTE" &&
                  activeList?.map((data) => (
                    <Select.Option value={data?.id}>
                      {data?.instituteName}
                    </Select.Option>
                  ))}
                {record?.taskRelateTo === "COMPANY" &&
                  activeList?.map((data) => (
                    <Select.Option value={data?.id}>
                      {data?.companyName} ({data?.email})
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            {/* )} */}
            <Form.Item name="taskManager" label="Task Manager">
              <Select
                mode="multiple"
                placeholder="Select Task manager"
                onChange={(value) => {
                  setTaskManager(value);
                }}
              >
                {employee?.map((emp) => (
                  <Select.Option value={emp?.id}>
                    {emp?.emailId} ({emp?.fullName}){" "}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <div style={{ margin: "1rem 0" }}>
              <Button
                type="outlined"
                size="small"
                icon={<UpOutlined />}
                onClick={() => {
                  setOpenMore(false);
                }}
              >
                View Less
              </Button>
              <Divider style={{ margin: 0 }} />
            </div>
          </div>
        )}
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Update Task
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UpdateTask;
