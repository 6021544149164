import { Card, Drawer, Image, Button, Divider, Timeline } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TaskImage from "../../Assets/taskImage.png";
import moment from "moment";
import UpdateTaskStatus from "../../Components/TaskTableComponent/UpdateTaskStatus";

export default function ViewTask({
  viewTaskModal,
  setVewTaskModal,
  recordId,
  updateType = "",
  fetchClientById,
  fetchCaseDetail,
  fetchCompanyByID,
  fetchInstituteById,
}) {
  const dispatch = useDispatch();
  const [Record, setRecord] = useState(null);
  const [activityLog, setActivityLog] = useState([]);
  const [current, setCurrent] = useState("taskDetail");
  const [isStatusUpdateModalVisible, setStatusUpdateModalVisible] =
    useState(false);

  console.log("log log", activityLog);
  const clientDetailsTabList = [
    {
      key: "taskDetail",
      tab: "Task Details",
    },
  ];
  useEffect(() => {
    fetchTaskDetails();
    fetchTaskActivityLog();
  }, []);

  const fetchTaskDetails = () => {
    dispatch({
      type: "FETCH_TASK_DETAILS_REQUEST",
      payload: {
        id: recordId,
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setRecord(res?.data);
      },
    });
  };

  const fetchTaskActivityLog = () => {
    dispatch({
      type: "FETCH_TASK_ACTIVITY_LOG_REQUEST",
      payload: {
        id: recordId,
      },
      isSuccessFn: true,
      successFunction: (res) => {
        setActivityLog(res?.data?.data);
      },
    });
  };

  return (
    <Drawer
      open={viewTaskModal}
      onClose={() => {
        if (updateType === "client") {
          fetchClientById();
        } else if (updateType === "case") {
          fetchCaseDetail();
        } else if (updateType === "company") {
          fetchCompanyByID();
        } else if (updateType === "institute") {
          fetchInstituteById();
        }
        setVewTaskModal(false);
      }}
      title="View Task"
      width={1100}
    >
      <Card style={{ borderRadius: "0.5rem" }} className="drawerStyle">
        <div className="flexRowWithoutStyle" style={{ gap: "1rem" }}>
          <Image className={"clientImage"} src={TaskImage} alt="" />
          <div
            className="flexColumnWithoutStyle"
            style={{
              justifyContent: "flex-start",
              width: "100%",
              gap: "0.5rem",
              minHeight: "10rem",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                {Record?.taskTitle}
              </div>
              <span
                style={{
                  border: "1px solid #cfcfcf",
                  padding: "0.25rem 0.5rem",
                  borderRadius: "0.5rem",
                }}
              >
                {Record?.taskStatus}
              </span>
            </div>

            <div className="flexRowWithoutStyle" style={{ gap: "1rem" }}>
              <span>Start Date: </span>
              <span>
                {Record?.startDate
                  ? moment(Record?.startDate).format("DD-MM-YYYY")
                  : null}
              </span>
            </div>
            <div className="flexRowWithoutStyle" style={{ gap: "1rem" }}>
              <span>Due Date: </span>
              <span>
                {Record?.dueDate
                  ? moment(Record?.dueDate).format("DD-MM-YYYY")
                  : null}
              </span>
            </div>
            <div className="flexRowWithoutStyle" style={{ gap: "1rem" }}>
              <span>Priority: </span>
              <span>{Record?.priority}</span>
            </div>
            <div
              className="flexRowWithoutStyle"
              style={{ gap: "0.3rem", position: "absolute", bottom: 0 }}
            >
              <Button
                type="primary"
                onClick={() => {
                  setStatusUpdateModalVisible(true);
                }}
              >
                Status Update
              </Button>
            </div>
          </div>
        </div>
        <Card
          bordered={false}
          tabList={clientDetailsTabList}
          activeTabKey={current}
          onTabChange={(key) => {
            setCurrent(key);
          }}
        />

        <div
          style={{
            display: "flex",
            gap: "0.5rem",
            flexDirection: "row",
          }}
        >
          <Card
            className="clientCard"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              maxHeight: "30rem",
              overflowY: "scroll",
            }}
          >
            <Timeline mode="left">
              {activityLog?.map((log) => (
                <Timeline.Item
                  label={
                    log?.activityTime
                      ? moment
                          .utc(log?.activityTime)
                          .local()
                          .format("D MMMM YYYY, h:mm A")
                      : ""
                  }
                >
                  <h4>{log?.taskStatus}</h4>
                  <p> {log?.activity}</p>
                </Timeline.Item>
              ))}
            </Timeline>
          </Card>
          <Card
            className="clientCard"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {detailFun(
              "Task Name",
              Record?.taskTitle ? Record?.taskTitle : "N/A"
            )}

            {detailFun(
              "Start Date",
              Record?.startDate
                ? moment(Record?.startDate).format("DD-MM-YYYY")
                : "N/A"
            )}
            {detailFun(
              "Due Date",
              Record?.dueDate
                ? moment(Record?.dueDate).format("DD-MM-YYYY")
                : "N/A"
            )}
            {detailFun(
              "Task Status",
              Record?.taskStatus ? Record?.taskStatus : "N/A"
            )}
            {detailFun(
              "Task Priority",
              Record?.priority ? Record?.priority : "N/A"
            )}
            {detailFun1(
              "AssignTo",
              Record?.assignedToResponse?.length > 0
                ? Record?.assignedToResponse
                : [{ email: "N/A" }]
            )}
            {detailFun(
              "Description",
              Record?.taskDescription ? Record?.taskDescription : "N/A"
            )}
            {detailFun(
              "Update Note",
              Record?.updateNote ? Record?.updateNote : "N/A"
            )}
            {detailFun(
              "Task Related",
              Record?.taskRelateTo ? Record?.taskRelateTo : "N/A"
            )}
            {detailFun1(
              "Task Manager",
              Record?.taskManagerResponse?.length > 0
                ? Record?.taskManagerResponse
                : [{ email: "N/A" }]
            )}
          </Card>
        </div>
      </Card>
      {isStatusUpdateModalVisible && (
        <UpdateTaskStatus
          record={Record}
          fetchTaskDetails={fetchTaskDetails}
          fetchTaskActivityLog={fetchTaskActivityLog}
          isStatusUpdateModalVisible={isStatusUpdateModalVisible}
          setStatusUpdateModalVisible={setStatusUpdateModalVisible}
        />
      )}
    </Drawer>
  );
}

const detailFun = (title, value) => {
  return (
    <div style={{ width: "100%" }}>
      <div className="flexRowSpaceBetween">
        {title}:<span>{value}</span>
      </div>
      <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
    </div>
  );
};

const detailFun1 = (title, record) => {
  return (
    <div style={{ width: "100%" }}>
      <div className="flexRowSpaceBetween" style={{ alignItems: "flex-start" }}>
        {title}:
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.2rem",
            alignItems: "flex-end",
          }}
        >
          {record?.map((data) => (
            <span>{data?.email}</span>
          ))}
        </div>
      </div>
      <Divider orientationMargin="0" style={{ margin: "0.5rem" }} />
    </div>
  );
};
