import React, { useEffect, useRef, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Alert,
  Avatar,
  Button,
  Input,
  Pagination,
  Popconfirm,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { MdOutlineDisabledVisible } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import SiderDemo from "../../Components/Layout/SiderDemo";
import UpdateTask from "./UpdateTask";
import AddTask from "./AddTask";
import { formattedDate, formattedDateTime } from "../../HelperFunction";
import TableSkeleton from "../../Helpers/TableSkeleton/tableSkelaton";
import { BsDownload } from "react-icons/bs";
import { CSVLink, CSVDownload } from "react-csv";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import ViewTask from "./ViewTask";

const { Search } = Input;

const Task = () => {
  const store = useSelector((state) => {
    return {
      taskState: state.TaskReducer,
      profileState: state.ProfileReducer,
      branchState: state.BranchReducer,
    };
  });
  const csvDownloadRef = useRef(null);

  const [searchValue, setSearchValue] = useState("");

  const [viewTaskModal, setVewTaskModal] = useState(false);
  const [recordId, setRecordId] = useState(null);
  const [isAddTaskModalVisible, setIsAddTaskModalVisible] = useState(false);
  const [isUpdateTaskModalVisible, setIsUpdateTaskModalVisible] =
    useState(false);
  const [record, setRecord] = useState({});
  const [taskType, setTaskType] = useState("get-assigned-to");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedVisible, setSelectedVisible] = useState(false);
  const [dataArray, setDataArray] = useState([]);

  const dispatch = useDispatch();
  const onSearch = (e) => {
    dispatch({
      type: "SEARCH_TASKS_REQUEST",
      payload: {
        page: 1,
        pageSize: 10,
        searchKey: { keyword: e },
      },
    });
  };
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onShowSizeChange = (current, pageSize) => {
    window.scrollTo(0, 0);
    setPage(current);
    setPageSize(pageSize);
  };

  const columns = [
    {
      title: "Task Title",
      dataIndex: "taskTitle",
      sorter: (a, b) => {
        if (a.taskTitle < b.taskTitle) {
          return -1;
        }
        if (a.taskTitle > b.taskTitle) {
          return 1;
        }
        return 0;
      },
      align: "center",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      align: "center",
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      align: "center",
    },
    {
      title: "Task Status",
      dataIndex: "taskStatus",
      align: "center",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      align: "center",
    },
    {
      title: "Assigned By",
      dataIndex: "assignedBy",
      sorter: (a, b) => {
        if (a.assignedTo < b.assignedTo) {
          return -1;
        }
        if (a.assignedTo > b.assignedTo) {
          return 1;
        }
        return 0;
      },
      align: "center",
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo",
      align: "center",
      render: (data) => {
        return data?.length > 0 ? (
          <div
            style={{ display: "flex", gap: "0.2rem", flexDirection: "column" }}
          >
            {data?.map((item) => (
              <li
                style={{
                  padding: "0.1rem 0.5rem",
                  borderRadius: "0.5rem",
                  border: "1px solid #ccc",
                  background: "#e6f4ff30",
                  cursor: "pointer",
                }}
              >
                {item?.email}
              </li>
            ))}
          </div>
        ) : (
          "N/A"
        );
        // <ul>{data?.length > 0 && data?.map((assign) => <li>{assign}</li>)}</ul>
      },
    },
    // {
    //   title: "Update Added By",
    //   dataIndex: "updateAddedBy",
    //   align: "center",
    // },
    {
      title: "Actions",
      dataIndex: "actions",
      align: "center",
      fixed: "right",
    },
  ];

  useEffect(() => {
    if (taskType === "get-assigned-to") {
      dispatch({
        type: "FETCH_ASSIGNED_TO_TASK_REQUEST",
        payload: {
          page,
          pageSize,
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    } else if (taskType === "my-task") {
      dispatch({
        type: "FETCH_ASSIGNED_BY_TASK_REQUEST",
        payload: {
          page,
          pageSize,
          branchId:
            store.branchState?.branchRecord.id ??
            localStorage.getItem("branchId"),
        },
      });
    }
  }, [page, pageSize, taskType, store.branchState?.branchRecord]);

  useEffect(() => {
    if (
      dataArray &&
      csvDownloadRef &&
      csvDownloadRef.current &&
      csvDownloadRef.current.link
    ) {
      csvDownloadRef.current.link.click();
    }
  }, [dataArray]);
  let data = [];
  if (taskType === "get-assigned-to") {
    data = store?.taskState.assignedTotasks?.data?.map((dataObj) => {
      let taskStatus, priorityStatus;
      if (dataObj.taskStatus === "PENDING") {
        taskStatus = <p className="blueTag">PENDING</p>;
      } else if (dataObj.taskStatus === "ASSIGNED") {
        taskStatus = <p className="orangeTag">ASSIGNED</p>;
      } else {
        taskStatus = <p className="greenTag">COMPLETED</p>;
      }
      if (dataObj.priority === "HIGH") {
        priorityStatus = <p className="blueTag">HIGH</p>;
      } else if (dataObj.priority === "URGENT") {
        priorityStatus = <p className="redTag">URGENT</p>;
      } else {
        priorityStatus = <p className="greenTag">NORMAL</p>;
      }

      return {
        key: dataObj.id,
        taskTitle: dataObj.taskTitle,
        startDate: formattedDate(dataObj.startDate),
        dueDate: formattedDate(dataObj.dueDate),
        taskStatus: taskStatus,
        priority: priorityStatus,
        assignedTo: dataObj.assignedToResponse,
        assignedBy: dataObj.assignedBy?.emailId,
        updateAddedTimeAndDate: formattedDateTime(
          dataObj.updateAddedTimeAndDate
        ),
        updateAddedBy: dataObj.updateAddedBy?.emailId,

        actions: (
          <Space size="middle">
            {store.profileState?.profile?.department?.rolesAndPermissionList?.TASKS?.includes(
              "UPDATE"
            ) && (
              <Tooltip title="Update task">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateTaskModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}

            <Tooltip placement="topRight" title="View Task">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setVewTaskModal(true);
                    // setRecord(dataObj);
                    setRecordId(dataObj?.id);
                  }}
                >
                  <FaEye />
                </div>
              </a>
            </Tooltip>
            {/* <Tooltip title="Delete task">
              <Popconfirm
                title="Are you sure to delete this task?"
                onConfirm={() => {
                  dispatch({
                    type: "DELETE_TASK_REQUEST",
                    payload: { id: dataObj.id },
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip> */}
            {/* <Tooltip title="Toggle task status">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    const actionStatus =
                      dataObj.status === "INACTIVE" ? "active" : "inactive";
                    dispatch({
                      type: "TOGGLE_TASK_STATUS_REQUEST",
                      payload: { id: dataObj.id, actionStatus },
                    });
                  }}
                >
                  <MdOutlineDisabledVisible />
                </div>
              </a>
            </Tooltip> */}
          </Space>
        ),
      };
    });
  } else {
    data = store?.taskState.myTask?.data?.map((dataObj) => {
      let taskStatus, priorityStatus;
      if (dataObj.taskStatus === "PENDING") {
        taskStatus = <p className="blueTag">PENDING</p>;
      } else if (dataObj.taskStatus === "ASSIGNED") {
        taskStatus = <p className="orangeTag">ASSIGNED</p>;
      } else {
        taskStatus = <p className="greenTag">COMPLETED</p>;
      }
      if (dataObj.priority === "HIGH") {
        priorityStatus = <p className="blueTag">HIGH</p>;
      } else if (dataObj.priority === "URGENT") {
        priorityStatus = <p className="redTag">URGENT</p>;
      } else {
        priorityStatus = <p className="greenTag">NORMAL</p>;
      }
      return {
        key: dataObj.id,
        taskTitle: dataObj.taskTitle,
        startDate: formattedDate(dataObj.startDate),
        dueDate: formattedDate(dataObj.dueDate),
        taskStatus: taskStatus,
        priority: priorityStatus,
        assignedBy: dataObj.assignedBy?.emailId,
        assignedTo: dataObj.assignedToResponse,
        updateAddedTimeAndDate: formattedDateTime(
          dataObj.updateAddedTimeAndDate
        ),
        updateAddedBy: dataObj.updateAddedBy?.emailId,
        actions: (
          <Space size="middle">
            {store.profileState?.profile?.department?.rolesAndPermissionList?.TASKS?.includes(
              "UPDATE"
            ) && (
              <Tooltip title="Update task">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      setIsUpdateTaskModalVisible(true);
                      setRecord(dataObj);
                    }}
                  >
                    <EditOutlined />
                  </div>
                </a>
              </Tooltip>
            )}
            <Tooltip placement="topRight" title="View Task">
              <a>
                <div
                  className="bordered"
                  onClick={() => {
                    setVewTaskModal(true);
                    // setRecord(dataObj);
                    setRecordId(dataObj?.id);
                  }}
                >
                  <FaEye />
                </div>
              </a>
            </Tooltip>
            {/* <Tooltip title="Delete task">
              <Popconfirm
                title="Are you sure to delete this task?"
                onConfirm={() => {
                  dispatch({
                    type: "DELETE_TASK_REQUEST",
                    payload: { id: dataObj.id },
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <a>
                  <div className="bordered">
                    <DeleteOutlined />
                  </div>
                </a>{" "}
              </Popconfirm>
            </Tooltip> */}
            {/* <Tooltip title="Toggle task status">
                <a>
                  <div
                    className="bordered"
                    onClick={() => {
                      const actionStatus =
                        dataObj.=== "INACTIVE" ? "active" : "inactive";
                      dispatch({
                        type: "TOGGLE_TASK_STATUS_REQUEST",
                        payload: { id: dataObj.id, actionStatus },
                      });
                    }}
                  >
                    <MdOutlineDisabledVisible />
                  </div>
                </a>
              </Tooltip> */}
          </Space>
        ),
      };
    });
  }
  const onSelectChange = (e, f, g) => {
    setSelectedRowKeys(e);
    setSelectedVisible(true);
    console.log("testing check", e);
  };
  const CSVSuccessFunction = (response) => {
    const dataArray = response?.data?.split("\n").map((row) => row.split(","));
    setDataArray(dataArray);
  };

  return (
    <SiderDemo>
      <div className="flexColumnwithoutStyle">
        <div className="leadFlexRow">
          <div style={{ width: "30%" }}>
            {store.profileState?.profile?.department?.rolesAndPermissionList?.TASKS?.includes(
              "ADD"
            ) && (
              <button
                className="button"
                onClick={() => {
                  setIsAddTaskModalVisible(true);
                }}
              >
                <span>Add Task</span>
              </button>
            )}
          </div>
          <Segmented
            options={[
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>My Tasks</div>
                  </div>
                ),
                value: "get-assigned-to",
              },
              {
                label: (
                  <div style={{ padding: 4 }}>
                    <div>Assigned To</div>
                  </div>
                ),
                value: "my-task",
              },
            ]}
            onChange={(value) => {
              setPage(1);
              setPageSize(10);
              setTaskType(value);
            }}
          />
          <div
            style={{
              display: "flex",
              width: "30%",
              alignItems: "center",
              gap: "0.3rem",
            }}
          >
            <Search
              className="search"
              style={{
                width: "100%",
                borderRadius: "10px ! important",
              }}
              placeholder="Search for task"
              allowClear
              size="large"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                onSearch(e.target.value);
              }}
            />
            {/* <div>
              <Tooltip title={"Upload CSV"} placement={"topLeft"}>
                <input
                  type={"file"}
                  name="file1"
                  style={{ display: "none" }}
                  id="file1"
                  onChange={(e) => {
                    console.log("from CSV file change", e);
                    const importCSVFormData = new FormData();
                    importCSVFormData.append("file", e.target.files[0]);
                    dispatch({
                      type: "IMPORT_TASK_CSV_FILE_REQUEST",
                      payload: importCSVFormData,
                    });
                  }}
                />
                <label for="file1">
                  <div
                    className="flexCenter"
                    style={{
                      border: "1px solid #d9d9d9",
                      borderRadius: "50%",
                      padding: "0.3rem",
                      height: "2rem",
                      width: "2rem",
                    }}
                  >
                    <AiOutlineCloudUpload style={{ fontSize: "20px" }} />
                  </div>
                </label>
              </Tooltip>
            </div> */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          {store?.taskState.isLoading ? (
            <TableSkeleton />
          ) : (
            <>
              {selectedRowKeys.length > 0 && selectedVisible && (
                <Alert
                  message={`Selected ${selectedRowKeys.length} items.`}
                  type="success"
                  closable
                  afterClose={() => {
                    setSelectedVisible(false);
                    setSelectedRowKeys([]);
                  }}
                  action={
                    <Tooltip title={"Download CSV"}>
                      {store.profileState?.profile?.department?.rolesAndPermissionList?.TASKS?.includes(
                        "DOWNLOAD_CSV"
                      ) && (
                        <Button
                          size="small"
                          type="primary"
                          onClick={(e) => {
                            const csvPayload = {
                              taskIds: selectedRowKeys?.map((dataObj) => {
                                return {
                                  id: dataObj,
                                };
                              }),
                            };

                            dispatch({
                              type: "DOWNLOAD_TASK_CSV_REQUEST",
                              payload: csvPayload,
                              fromTask: true,
                              CSVSuccessFunction: CSVSuccessFunction,
                            });
                          }}
                        >
                          Download CSV
                        </Button>
                      )}
                      <CSVLink
                        ref={csvDownloadRef}
                        asyncOnClick={true}
                        data={dataArray}
                        target="_blank"
                        filename="Task.csv"
                      />
                    </Tooltip>
                  }
                />
              )}
              <Table
                bordered
                rowSelection={{
                  selectedRowKeys,
                  onChange: onSelectChange,
                }}
                columns={columns}
                dataSource={data}
                pagination={false}
              />
              {taskType === "get-assigned-to" &&
              store?.taskState?.assignedTotasks?.totalData ? (
                <Pagination
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    alignSelf: "flex-end",
                  }}
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  defaultCurrent={1}
                  current={page}
                  defaultPageSize={pageSize}
                  onChange={onShowSizeChange}
                  total={store?.taskState?.assignedTotasks?.totalPage * 10}
                />
              ) : null}
              {taskType === "my-task" && store?.taskState?.myTask?.totalData ? (
                <Pagination
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    alignSelf: "flex-end",
                  }}
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  defaultCurrent={1}
                  current={page}
                  defaultPageSize={pageSize}
                  onChange={onShowSizeChange}
                  total={store?.taskState?.myTask?.totalPage * 10}
                />
              ) : null}
            </>
          )}
        </div>
      </div>
      {isAddTaskModalVisible && (
        <AddTask
          isAddTaskModalVisible={isAddTaskModalVisible}
          setIsAddTaskModalVisible={setIsAddTaskModalVisible}
        />
      )}
      {isUpdateTaskModalVisible && (
        <UpdateTask
          record={record}
          isUpdateTaskModalVisible={isUpdateTaskModalVisible}
          setIsUpdateTaskModalVisible={setIsUpdateTaskModalVisible}
        />
      )}

      {viewTaskModal && (
        <ViewTask
          viewTaskModal={viewTaskModal}
          setVewTaskModal={setVewTaskModal}
          recordId={recordId}
        />
      )}
    </SiderDemo>
  );
};

export default Task;
